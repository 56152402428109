import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthHoc from './hoc/AuthHoc';
import {
  ACCESS_DENIED_PAGE_URL,
  NETWORK_ERROR_PAGE_URL,
  ROOT_PAGE_URL,
  SUCCESS_WAIT_PAGE_URL,
} from './constants/pageUrls';
import AccessDeniedPage from './page/AccessDeniedPage';
import { SuccessWaitPage } from './page/SuccessWaitPage';
import NetworkErrorPage from './page/NetworkErrorPage';
import CustomLoading from './compositions/CustomLoading';

const Router = () => {
  return (
    <Suspense fallback={<CustomLoading />}>
      <Routes>
        <Route element={<AuthHoc />}>
          <Route path={ROOT_PAGE_URL} element={<RootPage />} />
        </Route>
        {/* Without AuthHoc */}
        <Route path={SUCCESS_WAIT_PAGE_URL} element={<SuccessWaitPage />} />
        <Route path={ACCESS_DENIED_PAGE_URL} element={<AccessDeniedPage />} />
        <Route path={NETWORK_ERROR_PAGE_URL} element={<NetworkErrorPage />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
const RootPage = lazy(() => import('./page/RootPage'));
