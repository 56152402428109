import { theme as ITheme } from '@imago-cloud/design-system';
import { imagoI18n } from '@imago-cloud/front-module';
import { createTheme } from '@mui/material';
import { useAuthContext } from './auth/useAuthContext';

const MOBILE_MIN_WIDTH = 0;
const TABLET_MIN_WIDTH = 680;
const DESKTOP_MIN_WIDTH = 1280;
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    desktop: true;
  }
}
export const Theme = () => {
  const {
    userInfo: { language },
  } = useAuthContext();
  const { typography: i18nTypography, MuiTypography: i18nMuiTypography } = imagoI18n.useI18nFont(language);

  return createTheme({
    ...ITheme,
    breakpoints: {
      values: {
        mobile: MOBILE_MIN_WIDTH,
        tablet: TABLET_MIN_WIDTH,
        desktop: DESKTOP_MIN_WIDTH,
      },
    },
    typography: {
      ...i18nTypography,
    },
    components: {
      MuiTypography: {
        ...i18nMuiTypography,
        ...ITheme.components?.MuiTypography,
      },
      ...ITheme.components,
      MuiButtonBase: {
        styleOverrides: {
          root: {},
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            [ITheme.breakpoints.down(TABLET_MIN_WIDTH)]: {
              margin: '16px',
            },
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            [ITheme.breakpoints.down(TABLET_MIN_WIDTH)]: {
              ...ITheme.typography.H18,
              padding: '20px 24px',
            },
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            [ITheme.breakpoints.down(TABLET_MIN_WIDTH)]: {
              padding: '24px',
            },
          },
        },
      },
    },
  });
};
