// COMMON (E.S: Common)
export const USER_INFO_API = 'cloud/api/get-user';

// SIGN IN | UP (E.S: User)
export const LOG_IN_API = 'cloud/api/authenticate';
export const LOG_OUT_API = 'cloud/api/logout';
export const REFRESH_TOKEN_API = 'account/auth/token/refresh';

export const CREDIT_VOUCHER_API = 'cloud/api/v2/credit-vouchers';
export const PAYMENYWALL_WIDGET_API = 'cloud/api/v2/paymentwalls/widget';
export const CREDIT_BALANCE_API = 'cloud/api/v2/credits/balance';
export const PAYMENTWALL_CHECK_API = 'cloud/api/v2/paymentwalls/check';
