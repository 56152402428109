import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { handleCheckAccessToken, updateUserInfo } from '../api/common';
import { ACCESS_DENIED_PAGE_URL } from '../constants/pageUrls';
import { useNavigate } from 'react-router-dom';
import { isPermissionExist } from '../util/isPermissionExist';

type AuthProviderType = {
  isLoading: boolean;
  isAuthorised: boolean;
  emailFullName: { email: string; fullName: string };
  userInfo: { tenantId: string; userId: string; language: string };
};

export const AuthContext = createContext<AuthProviderType | null>(null);

type AuthProviderProps = {
  children: React.ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthorised, setIsAuthorised] = useState(false);
  const [emailFullName, setEmailFullName] = useState({ email: '', fullName: '' });
  const [userInfo, setUserInfo] = useState({ tenantId: '', userId: '', language: 'en' });

  const initialize = useCallback(async () => {
    try {
      //
      const { accessToken, success } = await handleCheckAccessToken();

      const { isAuthorised, userInfo } = await updateUserInfo();
      const { email, fullName, tenantId, _id } = userInfo?.profileInfo;
      const permissionsInfo = userInfo?.permissionsInfo;
      const { language } = userInfo?.settingInfo;

      const isAccessdDeniedPermissons =
        !isPermissionExist(permissionsInfo, 'credit:balance') ||
        !isPermissionExist(permissionsInfo, 'credit_voucher:purchase');
      setEmailFullName({ email, fullName });
      setUserInfo({ tenantId, userId: _id, language });

      if (isAccessdDeniedPermissons) navigate(ACCESS_DENIED_PAGE_URL, { replace: true });
      if (!accessToken || !success) {
        throw new Error('Invalid Accesss.');
      }
      setIsAuthorised(isAuthorised);
    } catch (e) {
      alert(e.message);
      throw e;
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!document.referrer && !navigator.userAgent.includes('Electron')) {
      return navigate(ACCESS_DENIED_PAGE_URL, { replace: true });
    }
    initialize();
  }, [initialize]);

  const memoizedValue = useMemo(
    () => ({
      isLoading,
      isAuthorised,
      emailFullName,
      userInfo,
    }),
    [isLoading, isAuthorised, emailFullName, userInfo],
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
};
