import { useMutation } from '@tanstack/react-query';
import axiosInstance from '../AxiosInstance';
import { PAYMENTWALL_CHECK_API, PAYMENYWALL_WIDGET_API } from '../constants/apiUrl';

export interface IPaymentWidget {
  data: { creditVoucherId: string };
}

export const postPaymentWidget = ({ data }: IPaymentWidget) =>
  axiosInstance.post(PAYMENYWALL_WIDGET_API, data).then((res) => res.data);

export const usePostPaymentWidget = () => {
  return useMutation(({ data }: IPaymentWidget) => postPaymentWidget({ data }), {});
};

export interface IPaymentCheck {
  data: {
    invoiceId: string;
    creditVoucherId: string;
  };
}
export const patchPaymentCheck = ({ data }: IPaymentCheck) =>
  axiosInstance.patch(PAYMENTWALL_CHECK_API, data).then((res) => {
    // throw new Error('PAYMENT_PENDING') // Activate Test Code
    if (res.data.success === true && res.data.data.status === 'PAYMENT_PENDING') {
      throw new Error('PAYMENT_PENDING');
    } else {
      return res.data;
    }
  });

export const usePatchPaymentCheck = () => {
  return useMutation(({ data }: IPaymentCheck) => patchPaymentCheck({ data }), {
    retry: (failureCount, error) => {
      if (failureCount === 10) {
        return false;
      }
      // type guard
      if (typeof error === 'object' && error !== null && 'message' in error) {
        if (error.message === 'PAYMENT_PENDING') {
          return true;
        }
      }
      return false;
    },
    retryDelay: (_retryAttempt, _error) => {
      return 6000;
    },
  });
};
