import { useMediaQuery } from '@mui/material';

export const useResponsive = () => {
  const isClient = useMediaQuery(`(min-width: 0px)`);
  const isMobile = useMediaQuery(`(max-width: 679px)`);

  const isTablet = useMediaQuery(`(max-width:1320px)`);

  const responsiveCSS = <T, M>(nonMobile: T, mobile: M) => {
    if (isMobile) return mobile;
    else return nonMobile;
  };
  return { isClient, isMobile, isTablet, responsiveCSS };
};
