import React, { useEffect } from 'react';
import { Theme } from './theme';
import { ThemeProvider } from '@imago-cloud/design-system';
import Router from './Router';
import { RecoilRoot } from 'recoil';
import { GlobalDialogueSwitch } from './compositions/GlobalDialogueSwitch';
import { AxiosInterceptor } from './AxiosInstance';
import { dataDogRumInit } from './lib/datadog';
import { useAuthContext } from './auth/useAuthContext';
import { imagoI18n } from '@imago-cloud/front-module';
import './i18n';

dataDogRumInit();
function App() {
  const {
    userInfo: { language },
  } = useAuthContext();

  imagoI18n.useI18nInitialSet(language);

  return (
    <RecoilRoot>
      <AxiosInterceptor>
        <ThemeProvider theme={Theme()}>
          <GlobalDialogueSwitch />
          <Router />
        </ThemeProvider>
      </AxiosInterceptor>
    </RecoilRoot>
  );
}

export default App;
