import axios, { AxiosError } from 'axios';
import React, { useEffect } from 'react';

import { REFRESH_TOKEN_API } from './constants/apiUrl';
import { NETWORK_DISCONNECTION_CODE, REFRESH_TOKEN_EXPIRED_CODE } from './constants/errorCode';
import { useSetRecoilState } from 'recoil';
import { GLOBAL_DIALOGUE_STATE, globalDialogueStatusAtom } from './recoil/globalDialogueStatusAtom';
import { useNavigate } from 'react-router-dom';
import { ACCESS_DENIED_PAGE_URL, NETWORK_ERROR_PAGE_URL } from './constants/pageUrls';
import { useAuthContext } from './auth/useAuthContext';
import ImagoEvent from '@imago-cloud/action-log';
import { isImagoworks } from './util/general';

export const NETWORK_DISCONNECTION = 'NETWORK_DISCONNECTION';
export const REFRESH_TOKEN_EXPIRED = 'REFRESH_TOKEN_EXPIRED';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  timeout: 0,
});

const AxiosInterceptor = ({ children }: { children: React.ReactElement | null }) => {
  const setGlobalDialogueState = useSetRecoilState(globalDialogueStatusAtom);

  const navigate = useNavigate();

  // useEffect(() => {
  //   const initialize = async () => {
  //     const res = await handleCheckAccessToken();
  //     const info = await updateUserInfo();
  //     console.log(res);
  //     console.log(info);
  //   };
  //   initialize();
  // }, [])

  useEffect(() => {
    const responseInterceptor = (response: any) => {
      return response;
    };
    const errorInterceptor = async (error: any) => {
      const originalConfig = error?.config;

      const errorCode = error?.response?.data?.errorCode;

      // navigate 하지 말고 다뤄봐: 1. 라우터 타지 말고 또는 2. interceptors 안에서 다루던가. 3. 아예 authProvide를 만들어.
      if ((error as AxiosError).code === NETWORK_DISCONNECTION_CODE) {
        const {
          userInfo: { tenantId, userId },
          emailFullName: { email },
        } = useAuthContext();
        ImagoEvent.error.errorPageAppeared({
          userId,
          isImagoworks: isImagoworks(email),
          tenantId,
          ErrorPage: window.location.pathname,
          ErrorCode: errorCode,
          ErrorMessage: 'Network Error',
        });
        navigate(NETWORK_ERROR_PAGE_URL); // 아니면 여기가서 retry를 해!
      }

      if (errorCode === REFRESH_TOKEN_EXPIRED_CODE) {
        const isXUserIdExist = instance.defaults.headers.common['x-user-id'] || originalConfig.headers['x-user-id'];
        // isXUserId가 존재할 경우 처음 인증이 아니므로 refresh token Dialogue 표시,
        if (isXUserIdExist) {
          setGlobalDialogueState(GLOBAL_DIALOGUE_STATE.REFRESH_TOKEN_INVALID);
          return Promise.reject(error);
        } else {
          // isXUserId가 없을 경우 인증이 없으므로, access dinied page로 navigate
          navigate(ACCESS_DENIED_PAGE_URL, { replace: true });
          return Promise.reject((error as AxiosError).response?.data);
        }
      }
      if (error?.response?.status === 401) {
        // ACCESS TOKEN 만료 및 재발급
        try {
          const response = await instance.patch(REFRESH_TOKEN_API);
          const { accessToken } = response.data.data;
          instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
          originalConfig.headers.Authorization = `Bearer ${accessToken}`;

          return instance(originalConfig);
        } catch (err) {
          return Promise.reject((err as AxiosError).response?.data);
        }
      }
      return Promise.reject(error.response.data);
    };

    const interceptor = instance.interceptors.response.use(responseInterceptor, errorInterceptor);

    return () => {
      instance.interceptors.response.eject(interceptor);
    };
  }, []);

  return children;
};

export const addHeaderXUserID = (id: string) => {
  instance.defaults.headers.common['x-user-id'] = `${id}`;
};

export default instance;
export { AxiosInterceptor };
