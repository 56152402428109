import axiosInstance from '../AxiosInstance';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useGetCreditBalance, useGetCreditVouchers, usePatchPaymentCheck, usePostPaymentWidget } from '../api';
import { ICreditVoucher } from '../types/creditVoucher';
import { isMobileEnv } from '../util/isMobileEnv';
import { useNavigate } from 'react-router-dom';
import { SUCCESS_WAIT_PAGE_URL } from '../constants/pageUrls';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { globalDialogueStatusAtom } from '../recoil/globalDialogueStatusAtom';
import { paymentWallStatusAtom } from '../recoil/paymentWallStatusAtom';
import useTransactionImagoEvent from './useTransactionImagoEvent';

type PaymentSuccessType = {
  event: 'paymentSuccess';
  data: {
    object: string;
    id: string;
    created: number;
    amount: string;
    currency: string;
    refunded: boolean;
    risk: string;
    uid: string;
    product_id: string;
    payment_system: string;
  };
};

const PaymentWallCallback = {
  PaymentSuccess: 'paymentSuccess',
  WidgetLoaded: 'widgetLoaded',
  WidgetSizeChanged: 'widgetSizeChanged',
  PaymentProcessingStart: 'paymentProcessingStart',
  PaymentProcessingEnd: 'paymentProcessingEnd',
};

export type useCreditHook = ReturnType<typeof useCreditHook>;

export const useCreditHook = () => {
  const [showBackdrop, setShowBackdrop] = useState('');
  const [isPaymentSuccess, setIsPaymentSuccess] = useState<boolean | null>(null);
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [selectedCreditItem, setSelectedCreditItem] = useState<ICreditVoucher | null>(null);
  const [resultData, setResultData] = useState({ purchasedCredit: 0, balance: 0 });
  const navigate = useNavigate();

  // Imago Log
  const { handleChargeCreditEvent } = useTransactionImagoEvent();

  const resetGlobalDialogueState = useResetRecoilState(globalDialogueStatusAtom);
  const paymentWallStatus = useRecoilValue(paymentWallStatusAtom);
  const setPaymentWallStatus = useSetRecoilState(paymentWallStatusAtom);

  const invoiceId = useRef('');

  useEffect(() => {
    return () => {
      setSelectedCreditItem(null);
      setIsPaymentSuccess(null);
    };
  }, []);

  const isMobile = useMemo(() => {
    return isMobileEnv() && document.referrer;
  }, []);

  const handleMessage = useCallback(
    async (event: MessageEvent) => {
      try {
        if (event.origin !== 'https://api.paymentwall.com') return;
        const eventData = JSON.parse(event.data);
        const { event: eventStatus } = eventData || {};
        // console.log(eventStatus);

        if (eventStatus === 'paymentProcessingEnd') {
          setIsPaymentProcessing(true);
        }
        if (eventStatus == 'paymentSuccess') {
          // handle the successful payment scenario
          setIsPaymentProcessing(false);
          setPaymentWallStatus(true);

          setTimeout(() => {
            // onProcessClick();
            setShowBackdrop('');
          }, 2000);
        }
      } catch (e) {
        // console.error(e);
        throw e;
      }
    },
    [selectedCreditItem?.creditVoucherId],
  );

  useEffect(() => {
    window.addEventListener('message', handleMessage, false);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [selectedCreditItem?.creditVoucherId]);

  const { data: rawCreditVoucher, isLoading: isCreditVoucherLoading } = useGetCreditVouchers();
  const { data: rawCreditBalance, isLoading: isCreditBalanceLoading } = useGetCreditBalance();

  const mutation = usePostPaymentWidget();
  const paymentMutation = usePatchPaymentCheck();
  const creditVoucherList = rawCreditVoucher?.data;
  const creditAmount = rawCreditBalance?.data.balance ?? 0;

  const isLoading = isCreditVoucherLoading || isCreditBalanceLoading;
  const waitingServer = paymentMutation.isLoading;

  // 1. Select Vouchers
  const onSelectItemClick = (item: ICreditVoucher) => {
    setSelectedCreditItem(item);
    handleChargeCreditEvent({
      voucherUnit: item.creditAmount,
      chargeCreditStep: 'amountClick',
    });
  };

  // 2. Checkbox Agreement
  const onSubmit = async () => {
    try {
      const { data } = await mutation.mutateAsync({
        data: { creditVoucherId: selectedCreditItem?.creditVoucherId ?? '' },
      });
      setShowBackdrop(data.widgetUrl);

      const url = new URL(data.widgetUrl);
      const invoiceIdParam = url.searchParams.get('invoiceId') ?? '';
      invoiceId.current = invoiceIdParam;

      handleChargeCreditEvent({
        chargeCreditStep: 'continuePayment',
        transactionId: invoiceIdParam,
      });
    } catch (e) {
      // console.log(e);
      throw e;
    }
  };

  const onBackClick = () => {
    setSelectedCreditItem(null);
    handleChargeCreditEvent({
      chargeCreditStep: 'backClick',
    });
  };

  const onProcessClick = async () => {
    try {
      const { success, data } = await paymentMutation.mutateAsync({
        data: {
          creditVoucherId: selectedCreditItem?.creditVoucherId ?? '',
          invoiceId: invoiceId.current,
        },
      });
      // console.log(data);

      //TODO: 아직은 끄기만 하면 Error
      setIsPaymentSuccess(success);
      setResultData({ purchasedCredit: data.purchasedCredit, balance: data.balance });
      handleChargeCreditEvent({
        chargeCreditStep: 'chargeSuccess',
        balanceChanged: data.purchasedCredit,
        balanceFinal: data.balance,
      });
      // return success;
    } catch (err) {
      navigate(SUCCESS_WAIT_PAGE_URL, { replace: true });
      resetGlobalDialogueState();
    }
  };

  useEffect(() => {
    // if (paymentWallStatus && !isPaymentSuccess) {
    if (!isPaymentProcessing && paymentWallStatus && !isPaymentSuccess) {
      setTimeout(() => {
        // console.log('ProcessClick!')
        onProcessClick();
      }, 2000);
    }
  }, [isPaymentProcessing, paymentWallStatus, isPaymentSuccess]);

  const onClose = () => {
    if (isMobile) {
      history.back();
    } else {
      window.close();
    }
  };
  return {
    creditAmount,
    selectedCreditItem,
    creditVoucherList,
    onBackClick,
    onSubmit,
    onSelectItemClick,
    showBackdrop,
    isLoading,
    onClose,
    isPaymentSuccess,
    isPaymentProcessing,
    resultData,
    isMobile,
    waitingServer,
  };
};
