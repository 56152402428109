import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { updateUserInfo } from '../api/common';

import { isPermissionExist } from '../util/isPermissionExist';
import { useSetRecoilState } from 'recoil';

import { ACCESS_DENIED_PAGE_URL } from '../constants/pageUrls';
import { userStateAtom } from '../recoil/userStateAtom';
import { useAuthContext } from '../auth/useAuthContext';
import { Loading } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import CustomLoading from "../compositions/CustomLoading";

const AuthHoc = () => {
  const setUserState = useSetRecoilState(userStateAtom);
  const { isLoading, isAuthorised, emailFullName } = useAuthContext();

  useEffect(() => {
    if (!emailFullName) return;
    setUserState({ email: emailFullName.email, fullName: emailFullName.fullName });
  }, [emailFullName]);

  if (isLoading || !isAuthorised) {
    return (
      <CustomLoading />
    );
  }
  return <Outlet />;
};

export default AuthHoc;
