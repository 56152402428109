import { useQuery } from '@tanstack/react-query';
import axiosInstance from '../AxiosInstance';
import { CREDIT_VOUCHER_API } from './apiURL';

const getCreditVouchers = () => {
  return axiosInstance.get(CREDIT_VOUCHER_API).then((res) => res.data);
};

export const useGetCreditVouchers = () => {
  return useQuery(['get-Credit-voucher'], () => getCreditVouchers(), { refetchOnWindowFocus: false });
};
