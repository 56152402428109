import {
  Button,
  Dialogue,
  DialogueActions,
  DialogueContent,
  DialogueTitle,
  Ricon,
  theme,
  Typography,
} from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import React from 'react';
import { useResponsive } from '../../hooks';
import { imagoI18n } from '@imago-cloud/front-module';

export const RefreshTokenInvalidDialogue = () => {
  const { Translate } = imagoI18n.useTranslate();

  const { responsiveCSS } = useResponsive();
  const onCloseButtonClick = () => {
    window.close();
  };
  return (
    <Dialogue open>
      <DialogueTitle>
        <Stack gap="8px" direction="row" alignItems="center">
          <Ricon icon="ri-alert-fill" svgProps={{ width: 24, height: 24, fill: theme.palette.error.main }} />
          <Translate id="session_expired_notice">Session expired</Translate>
        </Stack>
      </DialogueTitle>
      <DialogueContent sx={{ px: '28px', pb: '32px' }}>
        <Typography variant={responsiveCSS('Body18', 'Body16')}>
          <Translate id="session_expired_message">Your session has expired.</Translate>
        </Typography>
      </DialogueContent>
      <DialogueActions>
        <Button
          id="sessionExpiredDialogueCloseBtn"
          variant="contained"
          size={responsiveCSS('36', '48')}
          color="primary"
          sx={{ minWidth: '100px' }}
          onClick={onCloseButtonClick}
        >
          <Translate id="close_action">Close</Translate>
        </Button>
      </DialogueActions>
    </Dialogue>
  );
};
