import { REFRESH_TOKEN_API, USER_INFO_API } from '../constants/apiUrl';
import AxiosInstance from '../AxiosInstance';
import axiosInstance, { addHeaderXUserID } from '../AxiosInstance';

// export const useLogoutMutation = () => {
//   // const { hostDomain } = useChannel();
//   return useMutation(() => AxiosInstance.post(LOG_OUT_API).then((res: any) => res.data), {
//     onSuccess: () => {
//       window.location.replace('/' as string);
//     },
//   });
// };

export const handleCheckAccessToken = async () => {
  // Access Token 확인 API 호출
  const response = await AxiosInstance.patch(REFRESH_TOKEN_API).then((res: any) => res.data);
  const token = response?.data.accessToken;
  AxiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  return {
    accessToken: token,
    success: response?.success,
  };
};

export const updateUserInfo = async () => {
  try {
    const response = await getUserInfo();
    const { user: profileInfo, setting: settingInfo, crownSetting, permissions: permissionsInfo } = response;
    const userInfo = {
      profileInfo,
      settingInfo,
      crownSetting,
      permissionsInfo,
    };
    const { _id } = profileInfo;
    addHeaderXUserID(_id);

    return { isAuthorised: !!_id, userInfo };
  } catch (err) {
    // console.error(err);
    throw err;
  }
};

export const getUserInfo = () => axiosInstance.get(USER_INFO_API).then((res) => res.data);
