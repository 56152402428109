import { useQuery } from '@tanstack/react-query';
import axiosInstance from '../AxiosInstance';
import { CREDIT_BALANCE_API } from './apiURL';

const getCreditBalance = () => {
  return axiosInstance.get(CREDIT_BALANCE_API).then((res) => res.data);
};

export const useGetCreditBalance = () => {
  return useQuery(['get-credit-balance'], () => getCreditBalance(), { retry: 3, retryDelay: 500 });
};
